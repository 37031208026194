@import '../../../../../../../styles/shared';

$regular-size: 110px;
$featured-size: 140px;

.wrapper {
  display: inline-flex;
  align-items: center;
  font-family: var(--hub-sectors-module-topic-font-family);
  text-decoration: none;
  background-color: #f2f2f2;
  border-radius: $regular-size;
  overflow: hidden;
  transition: background-color 0.2s;

  &:hover {
    background-color: #dadada;

    .avatar {
      opacity: 0;
    }

    .styledAvatar {
      left: 0;
      opacity: 1;
    }

    .avatarIcon {
      left: 0;
    }
  }
}

.regular {
  .brandImages {
    display: none;
  }

  .brandImage {
    &:not(:first-child) {
      display: none;
    }
  }

  &:hover {
    .brandImages {
      display: flex;
    }

    &.withBrandImages {
      .topicName {
        margin-top: -34px;
        visibility: hidden;
      }
    }
  }
}

.featured {
  $size: $featured-size;
  border-radius: $size;

  .avatar,
  .fakeAvatar,
  .styledAvatar {
    @include mq($from: desktop) {
      width: $size;
      height: $size;
    }
  }

  .styledAvatar {
    left: -$size;
  }

  .brandImages {
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  .brandImage {
    &:not(:first-child) {
      @include mq($until: tablet) {
        display: none;
      }
    }
  }
}

.avatarWrapper {
  position: relative;
}

.avatar,
.fakeAvatar,
.styledAvatar {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  transition: opacity 0.2s;

  @include mq($from: desktop) {
    width: $regular-size;
    height: $regular-size;
  }
}

.fakeAvatar {
  background-color: darken(#f2f2f2, 5%);
}

.styledAvatar {
  position: absolute;
  top: 0;
  left: -$regular-size;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-scheme-accent-main);
  transition: left 0.2s;
}

.avatarIcon {
  position: relative;
  left: -100%;
  font-size: 30px;
  color: #fff;
  transition: left 0.4s;
}

.body {
  padding: 0 40px 0 25px;
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  margin-bottom: 15px;
  padding: 0 16px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #6a6f71;
  border: 1px solid #6a6f71;
  border-radius: 24px;

  @include mq($until: desktop) {
    display: none;
  }
}

.topicName {
  font-family: var(--hub-sectors-module-topic-name-font-family);
  font-size: 22px;
  line-height: 24px;
  color: var(--hub-sectors-module-topic-color);

  @include mq($from: desktop) {
    font-size: 30px;
    line-height: 34px;
  }
}

.brandImages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.brandImagesTitle {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #6a6f71;
}

.images {
  display: flex;
  gap: 10px;
}

.brandImage {
  max-height: 22px;

  @include mq($from: desktop) {
    max-height: 24px;
  }
}

.invert {
  filter: invert(1);
}
