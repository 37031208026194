.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 60px;
  padding: 0 22px;
  text-decoration: none;
  color: var(--hub-sectors-module-topic-color);
  background: #f0f0f0;
  border-radius: 50px;
}

.topicName {
  font-family: var(--hub-sectors-module-topic-name-font-family);
  font-size: 16px;
  line-height: 24px;
}

.arrowIcon {
  position: relative;
  top: 2px;
}
